import { Link } from "gatsby";
import React, { useEffect } from "react";
import scheduleBanner1 from "../../assets/Images/About Us/contact_us_2x.png";
import scheduleBanner from "../../assets/Images/About Us/schedule_call_banner_2x.png";
import Button from "../../components/Button";
import "./MiniImageBanner.scss";

const MiniImageBanner = (props) => {
  useEffect(() => {
    const element = document.getElementById(props.miniImageBannerData.Id);
    element.style.backgroundImage = `url(${props.miniImageBannerData.MiniImageBannerBgImg})`;
    element.style.backgroundSize = "cover";
    element.style.backgroundRepeat = "no-repeat";
  }, [
    props.miniImageBannerData.MiniImageBannerBgImg,
    props.miniImageBannerData.Id,
  ]);
  const bannerImg =
    props.page === "about-us" ? scheduleBanner : scheduleBanner1;
  return (
    <React.Fragment>
      <div
        className={
          props.miniImageBannerData.bannerImageAbout === "yes"
            ? "banner-condition mini-image-banner-responsive"
            : "banner-condition mini-image-banner"
        }
        id={props.miniImageBannerData.Id}
      >
        <div className="body-container">
          {props.miniImageBannerData.route ? (
            <Link target="_blank" className="services-link" to="/contact-us">
              <div className="mini-image-banner-overlay">
                <div>
                  {props.miniImageBannerData.tag === "h3"
                    ? props.miniImageBannerData.HoverLinkContent && (
                        <h3
                          className={
                            props.miniImageBannerData.underLine === "no"
                              ? "no-underline mini-image-banner-title min-title-header"
                              : "red-line3letter mini-image-banner-title min-title-header ttc"
                          }
                          style={
                            props.miniImageBannerData &&
                            props.miniImageBannerData.inlineStyle && {
                              fontSize: "56px",
                            }
                          }
                        >
                          {props.miniImageBannerData.HoverLinkContent}
                        </h3>
                      )
                    : props.miniImageBannerData.tag === "h4"
                    ? props.miniImageBannerData.HoverLinkContent && (
                        <h4
                          className={
                            props.miniImageBannerData.underLine === "no"
                              ? "no-underline mini-image-banner-title min-title-header"
                              : "red-line3letter mini-image-banner-title min-title-header ttc"
                          }
                          style={
                            props.miniImageBannerData &&
                            props.miniImageBannerData.inlineStyle && {
                              fontSize: "56px",
                            }
                          }
                        >
                          {props.miniImageBannerData.HoverLinkContent}
                        </h4>
                      )
                    : props.miniImageBannerData.HoverLinkContent && (
                        <h2
                          className={
                            props.miniImageBannerData.underLine === "no"
                              ? "no-underline mini-image-banner-title min-title-header"
                              : "red-line3letter mini-image-banner-title min-title-header ttc"
                          }
                          style={
                            props.miniImageBannerData &&
                            props.miniImageBannerData.inlineStyle && {
                              fontSize: "56px",
                            }
                          }
                        >
                          {props.miniImageBannerData.HoverLinkContent}
                        </h2>
                      )}

                  {props.miniImageBannerData.NearHoverLinkContent && (
                    <h3 className="title-header2 float-right">
                      {props.miniImageBannerData.NearHoverLinkContent}
                    </h3>
                  )}
                  <h3
                    className={
                      !props.miniImageBannerData.HoverLinkContent
                        ? "mini-image-banner-content min-title-header"
                        : "mini-image-banner-title min-title-header"
                    }
                  >
                    {props.miniImageBannerData.PlainContent}
                  </h3>
                </div>
              </div>
              <h3 className="mini-image-banner-title white-header">
                {props.miniImageBannerData.whiteBoldContent}
              </h3>
              <Button
                customClassName={props.miniImageBannerData.btnClassName}
                variant={props.miniImageBannerData.btnVariant}
                color={props.miniImageBannerData.btnColor}
                label={props.miniImageBannerData.btnLabel}
                tip={props.miniImageBannerData.tip}
                tooltip={props.miniImageBannerData.tooltip}
                view="show"
                // routepath={props.MiniImageBanner1Data.route}
              />
            </Link>
          ) : (
            <div className="mini-image-banner-overlay">
              <div>
                {props.miniImageBannerData.HoverLinkContent && (
                  <h3 className="red-line3letter mini-image-banner-title min-title-header ttc">
                    {props.miniImageBannerData.HoverLinkContent}
                  </h3>
                )}
                {props.miniImageBannerData.NearHoverLinkContent && (
                  <h3 className="title-header2 float-right">
                    {props.miniImageBannerData.NearHoverLinkContent}
                  </h3>
                )}
                <h3
                  className={
                    !props.miniImageBannerData.HoverLinkContent
                      ? "mini-image-banner-content min-title-header"
                      : "mini-image-banner-title min-title-header"
                  }
                >
                  {props.miniImageBannerData.PlainContent}
                </h3>

                {/* <h3 className="mini-image-banner-title-content white-content">{props.miniImageBannerData.whiteContent}</h3> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <Link target="_blank" className="services-link" to="/contact-us">
        <img className="mbl-banner" src={bannerImg} alt={bannerImg} />
      </Link>
    </React.Fragment>
  );
};

export default MiniImageBanner;
