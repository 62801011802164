
import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import  { Typography }  from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import tablet from  "../../assets/Images/PopUp/tablet@2x.png";
import "./DownloadablePopup.scss"
const useStyles = makeStyles((theme) => ({
 paper:{
     position:'unset',
     borderRadius:'12px',
    //  width: '1366px',
    // height: '699px'
    // height:'560px'
    height: '479px'
 }
  }));
export default function FormDialog(props) {
    let formData = {
        firstName: "",
        lastName: "",
        organization: "",
      };
      const classes = useStyles();
  const [open, setOpen] = useState();
  const [contactusForm, setcontactusForm] = useState(formData);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.handlePopup(false);
  };
  const handleChange = (event, key) => {

    setcontactusForm({ ...contactusForm });
  
};
  return (
    <div>
      <Dialog classes={{ 
    root: classes.root, 
    paper: classes.paper }} className="no-padding" open={props.openPopup} onClose={handleClose} aria-labelledby="form-dialog-title">
         
        {/* <DialogTitle id="form-dialog-title">Download your free checklist</DialogTitle> */}
        <DialogContent  className="no-padding-top" >
        <Grid  container direction="row" spacing={6} >
            
        <Grid  className="grid-bg-red" item xs={12} sm={12} md={6}>
        <Grid item xs={12} sm={12} md={12}>
            <div className="img-div">
        <img className="animation-image" src={tablet} alt="tablet" />
        </div>
      </Grid>
        <Grid item xs={12} sm={12} md={12}>
        <Typography className="font-15-padding"><b>Save time and money</b> with our checklist. Identify key answers regarding: <br/><br />
        <b>  . Experience, contracts, USP<br />
            . Costs  <br/>
            . Methodology & tools</b>
          </Typography>
        </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <Typography className="font-15" >Download your free checklist</Typography>
        <div className="floating-dynamic">
            <input
              id="first_name"
              className="floating__input1"
              name="first_name"
              type="text"
              placeholder="First Name *"
              onChange={(event) => handleChange(event, "firstName")}
              value={contactusForm.firstName}
            />
            <label
              htmlFor="first_name"
              className="floating__label"
              data-content="First Name *"
            >
              <span className="hidden--visually">First Name</span>
            </label>
          </div>
          <div className="floating-dynamic">
            <input
              id="last_name"
              className="floating__input1"
              name="last_name"
              type="text"
              placeholder="Last Name"
              onChange={(event) => handleChange(event, "lastName")}
              value={contactusForm.lastName}
            />
            <label
              htmlFor="last_name"
              className="floating__label"
              data-content="Last Name"
            >
              <span className="hidden--visually">Last Name</span>
            </label>
          </div>
          <div className="floating-dynamic">
            <input
              id="email"
              className="floating__input1"
              name="email"
              type="email"
              placeholder="Email *"
              onChange={(event) => handleChange(event, "email")}
              value={contactusForm.email}
            />
            <label
              htmlFor="email"
              className="floating__label"
              data-content="Email *"
            >
              <span className="hidden--visually">Email</span>
            </label>
          </div>
          <div className="floating-dynamic">
            <input
              id="Organization"
              className="floating__input1"
              name="Company name"
              type="text"
              placeholder="Organization *"
              onChange={(event) => handleChange(event, "organization")}
              value={contactusForm.organization}
            />
            <label
              htmlFor="Company name"
              className="floating__label"
              data-content="Company name *"
            >
              <span className="hidden--visually">Company name</span>
            </label>
          </div>
          <Button style={{width:'240px'}} variant="contained" onClick={handleClose}  color="primary">
          Download checklist
          </Button>
        <Typography className="font-10 mt-10" >We’re committed to your privacy. Digiryte uses the information you provide to us to contact you about our relevant content, products, and services. Read our privacy policy.</Typography>
          </Grid>
            </Grid>
        </DialogContent>
      </Dialog>
      {/* </Slide> */}
    </div>
  );
}
