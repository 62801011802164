import React, { useEffect } from "react";
import "./MobileMovieSlider.scss";

const MobileMovieSlider = (props) => {
  useEffect(() => {
    document.getElementById(
      props.slideid
    ).style.backgroundImage = `url(${props.movieSlider.imageUrl})`;
  }, [props.movieSlider.imageUrl,props.slideid]);
  return (
    <div className="movie-slider-main-mob">
      
      <div className={props.componentName === "digiryte-pods-hght"  ? "tech-slideshow-mob1" : "tech-slideshow-mob"}>
        <div className="mover-1-mob" id={props.slideid}></div>
      </div>
    </div>
  );
};

export default MobileMovieSlider;
