import React from "react";
import "./ImageListDynamic.scss";
import Grid from "@material-ui/core/Grid";

const ImageListDynamic = (props) => {
  return (
    <div className="body-container image-slider-rsp">
   
   {props.ImagelistData.tag === "h5" ? (
        <h5 className="h3-header">{props.ImagelistData.ImageListTitle}</h5>
      ) : props.ImagelistData.tag === "h4" ? (
        <h4 className="h3-header">{props.ImagelistData.ImageListTitle}</h4>
      ) : (
        <h3 className="h3-header">{props.ImagelistData.ImageListTitle}</h3>
      )}
      <div className="imagelist-content">
        {props.ImagelistData.ImageListSubTitle}
      </div>
      {/* HIDING IT TEMPORARILY FOR FUTURE RELEASE */}
      {/* <Button
        icon={<img className="img-lst-svgicon" src={RightArrow} alt="click" />}
        customClassName="image-list-resize-btn"
        color={props.ImageListButtonColor}
        label={props.ImageListButtonLabel}
      /> */}
        {/* {props.awards && props.awards.map((item, index) => ( */}
            <Grid className="mt-25"style={{justifyContent: 'space-between'}} container direction="row" >
         {props.awardsData.map((item, index) => (
                <Grid
                key={index}
                className="image-slider-image-rsp2 margin-style"
                item
                xs={12}
                sm={4}
                md={2}
              >
              {/* <ImageList
              index={index}
                // customHeight="140"
                awardImage={item.image}
                awardTitle={item.title}
                awardSubTitle={item.years}
                awardAlt={item.imageAltTxt}
                tag= "h5"
                ImageListButtonColor="primary"
                ImageListButtonLabel= "Find out More"
              /> */}
                 <img
              className="award-logo"
              src={item.image}
              alt={item.imageAltTxt}
            />
            <div className="award-title">{item.title}</div>
            <div className="award-sub-title">{item.years}</div>
              </Grid>
        ))} 
         
   </Grid>
    </div>
  );
};

export default ImageListDynamic;
