import Grid from "@material-ui/core/Grid";
import React from "react";
import "./ImageList.scss";


const ImageList = (props) => {
  return (
    <div className="body-container image-slider-rsp">
      {props.ImagelistData.tag === "h5" ? (
        <h5 className="h3-header">{props.ImagelistData.ImageListTitle}</h5>
      ) : props.ImagelistData.tag === "h4" ? (
        <h4 className="h3-header">{props.ImagelistData.ImageListTitle}</h4>
      ) : (
        <h3 className="h3-header">{props.ImagelistData.ImageListTitle}</h3>
      )}
      <div className="imagelist-content">
        {props.ImagelistData.ImageListSubTitle}
      </div>
      {/* HIDING IT TEMPORARILY FOR FUTURE RELEASE */}
      {/* <Button
        icon={<img className="img-lst-svgicon" src={RightArrow} alt="click" />}
        customClassName="image-list-resize-btn"
        color={props.ImagelistData.ImageListButtonColor}
        label={props.ImagelistData.ImageListButtonLabel}
      /> */}

      <Grid className="mt-25" container direction="row" justify="space-between">
        {props.ImagelistData.awards.map((item, index) => (
          <Grid
            key={index}
            className="image-slider-image-rsp1"
            item
            xs={12}
            sm={4}
            md={2}
          >
            <img
              className="award-logo"
              src={item.awardImage}
              alt={props.awardAlt}
            />
            <div className="award-title">{item.awardTitle}</div>
            <div className="award-sub-title">{item.awardSubTitle}</div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ImageList;
